import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GoogleMaps from "../components/GoogleMaps";
import Reveal from "../components/Reveal";
import * as style from './kontakt.module.css'

import { AiOutlineMail } from 'react-icons/ai'
import { AiOutlinePhone } from 'react-icons/ai'
import { BsShop } from 'react-icons/bs'
import SiteMetadata from "../components/SiteMetadata";
import ContactForm from "../components/contactForm/ContactForm";

// eslint-disable-next-line
export const KontaktPageTempate = ({
  email,
  address, 
  metadata, 
  phone, 
  title, 
  description
}) => {

  return (
    <>
      <SiteMetadata {...metadata} />
      <Reveal>
        <div className="container-lg mt-5">
          <div className="row section">
            <div className="col-12">
              <h1 className="title-2 mb-2">{title}</h1>
              <p className={`${style.subtitle} mb-5`}>{description}</p>
            </div>
            <div className="col-md-6 mb-4">
              <div className={style.contactInfo}>
                <div className={style.header}>Kontakt info</div>
                <div className={`${style.contactBlock} d-flex align-items-center`}>
                  <div className={style.icon}>
                    <BsShop />
                  </div>
                  <div className="text">
                    <h2 className={style.blockTitle}>{address.title}</h2>
                    {address.texts.map(item => (
                      <p className={`${style.blockText} mb-0`}>{item.text}</p>
                    ))}
                  </div>
                </div>

                <div className={`${style.contactBlock} d-flex align-items-center`}>
                  <div className={style.icon}>
                    <AiOutlineMail />
                  </div>
                  <div className="text">
                    <h2 className={style.blockTitle}>{email.title}</h2>
                    {email.texts.map(item => (
                      <p className={`${style.blockText} mb-0`}>{item.text}</p>
                    ))}
                  </div>
                </div>

                <div className={`${style.contactBlock} d-flex align-items-center`}>
                  <div className={style.icon}>
                    <AiOutlinePhone />
                  </div>
                  <div className="text">
                    <h2 className={style.blockTitle}>{phone.title}</h2>
                    {phone.texts.map(item => (
                      <p className={`${style.blockText} mb-0`}>{item.text}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
               <ContactForm />
            </div>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <section id="map">
          <GoogleMaps />
        </section>
      </Reveal>
    </>
  );
};

KontaktPageTempate.propTypes = {
  metadata: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.object,
  address: PropTypes.object,
  phone: PropTypes.string,
};

const Kontakt = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <KontaktPageTempate
        email={frontmatter.email}
        address={frontmatter.address}
        metadata={frontmatter.metadata}
        phone={frontmatter.phone}
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  );
};

Kontakt.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default Kontakt;

export const pageQuery = graphql`
  query KontaktTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "kontakt" } }) {
      frontmatter {
        metadata {
          title
          description
        }
        title
        description
        address {
          title
          texts {
            text
          }
        }
        phone {
          title
          texts {
            text
          }
        }
        email {
          title
          texts {
            text
          }
        }
      }
    }
  }
`;


